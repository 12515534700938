import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Container } from './Container'
import type { ReactVideoProps } from './ReactVideo'
import { ReactVideoWithNoSSR } from './ReactVideoWithNoSSR'
import styles from './Video.module.sass'

export type VideoProps = ReactVideoProps & {
	rounded?: boolean | undefined
	primaryText?: string
}

export const Video: FunctionComponent<VideoProps> = (props) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={clsx(styles.video, props.rounded && styles.roundedStyles)}>
					<ReactVideoWithNoSSR {...props} />
				</div>
				{props.videoSource && (
					<div className={styles.sourceContainer}>
						<span className={styles.videoSource}>{props.videoSource}</span>
					</div>
				)}
			</Container>
		</div>
	)
}
